import React, { useState } from "react"
import Popup from "reactjs-popup"
import "./styles.css"
import Spinner from "../../images/spinner.svg"
import LogoShort from "../../images/logo_short.svg"
import Input from "../input"
import { useForm } from "react-hook-form"
import { useTranslation } from "../../utils"
import { toast } from "react-toastify"
import classNames from "classnames"

const ApplyForm = ({ children, title, request, onOpen, analyticsOnSubmit }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onBlur",
  })
  const [loading, setLoading] = useState(false)
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const phonePattern = /[\+()]*(?:\d[\s\-\.()xX]*){10,14}/

  const onSubmit = async (data, close) => {
    analyticsOnSubmit(
      data.first_name,
      data.last_name,
      data.company,
      data.email,
      data.phone
    )
    setLoading(true)
    const result = await request(data)

    if (result.isRequestFailed) {
      switch (result.errors) {
        case "INVALID_INPUT":
          toast(t("Demo request INVALID_INPUT"))
          break
        case "INTERNAL_ERROR":
          toast(t("Demo request INTERNAL_ERROR"))
          break
        default:
          toast(t("Demo request - failed"))
      }
    } else {
      toast(t("Demo request - success"))
      close()
    }
    setLoading(false)
  }

  return (
    <Popup
      trigger={children}
      contentStyle={{
        boxShadow: "0 20px 60px -2px rgba(27, 33, 58, 0.4)",
        width: "360px",
        margin: "auto auto",
        textAlign: "left",
        padding: "0",
      }}
      modal
      closeOnEscape
      closeOnDocumentClick
      onOpen={onOpen}
    >
      {close => (
        <div>
          {loading && (
            <div className="wt-loader-wrapper">
              <img src={Spinner} alt="spinner" />
            </div>
          )}
          <>
            <div className="wt-flex-column wt-flex-middle get-demo-header">
              <h2 className="get-demo-title">{t(`${title}`)}</h2>
            </div>
            <div className="get-demo-form">
              <div className="get-demo-logo">
                <img src={LogoShort} alt="Logo" />
              </div>
              <form onSubmit={handleSubmit(data => onSubmit(data, close))}>
                <Input
                  label={t("Your First Name")}
                  placeholder={t("Your First Name")}
                  name="first_name"
                  className={classNames("wt-margin-top-30", {
                    "wt-input_red-border": errors.first_name,
                  })}
                  ref={register({
                    required: true,
                    maxLength: 150,
                    pattern: /^([^0-9]*)$/,
                  })}
                />
                <Input
                  label={t("Your Last Name")}
                  placeholder={t("Your Last Name")}
                  name="last_name"
                  className={classNames("wt-margin-top-15", {
                    "wt-input_red-border": errors.last_name,
                  })}
                  ref={register({
                    required: true,
                    maxLength: 150,
                    pattern: /^([^0-9]*)$/,
                  })}
                />
                <Input
                  label={t("Your Company")}
                  placeholder={t("Your Company")}
                  className={classNames("wt-margin-top-15", {
                    "wt-input_red-border": errors.company,
                  })}
                  name="company"
                  ref={register({
                    required: true,
                    maxLength: 150,
                  })}
                  type="company"
                />
                <Input
                  label={t("Your Email")}
                  placeholder={t("Your Email")}
                  name="email"
                  className={classNames("wt-margin-top-15", {
                    "wt-input_red-border": errors.email,
                  })}
                  ref={register({ required: true, pattern: emailPattern })}
                />
                <Input
                  label={t("Phone")}
                  placeholder={t("Enter phone")}
                  className={classNames("wt-margin-top-15", {
                    "wt-input_red-border": errors.phone,
                  })}
                  name="phone"
                  ref={register({
                    required: true,
                    pattern: phonePattern,
                  })}
                  type="tel"
                />
                <button
                  type="submit"
                  className="wt-button wt-margin-top-15 wt-button--primary"
                >
                  Send
                </button>
              </form>
            </div>
          </>
        </div>
      )}
    </Popup>
  )
}

export default ApplyForm
