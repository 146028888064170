import { CONFIG } from "../config"

const requestDemo = async (data, url) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then(result => {
      if (result.ok) {
        return result.json()
      }
      return result.json().then(response => {
        throw new Error(response.error.message)
      })
    })
    .catch(err => ({ isRequestFailed: true, errors: err.message }))
}

export const requestAwarenessDemo = async data =>
  requestDemo(data, CONFIG?.AWARENESS_DEMO_API_URL)

export const requestLoadDemo = async data =>
  requestDemo(data, CONFIG?.LOAD_DEMO_API_URL)
