import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import Footer from "../../sections/footer"
import AwarenessSection from "../../sections/awareness/awareness-main"
import AwarenessMagicSection from "../../sections/awareness/awareness-magic"
import AwarenessCardsSection from "../../sections/awareness/awareness-cards"
import AwarenessTimeSection from "../../sections/awareness/awareness-time"

const AwarenessPage = () => {
  return (
    <Layout>
      <SEO title="Awareness-the most comfortable way to be safe" />
      <Header />
      <AwarenessSection />
      <AwarenessMagicSection />
      <AwarenessCardsSection />
      <AwarenessTimeSection />

      <Footer />
    </Layout>
  )
}

export default AwarenessPage
