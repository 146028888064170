import React, { useState } from "react"
import "./styles.css"
import { useTranslation } from "../../../utils"
import CsvFile from "../../../images/csv-file.svg"
import Project1 from "../../../images/project1.svg"
import Project2 from "../../../images/project2.svg"
import DragDropImg from "../../../images/drag&Drop.svg"
import FacebookImg from "../../../images/aw-facebook.svg"
import AwarenessNotebook from "../../../images/awareness_notebook.svg"
import AwarenessDashboard from "../../../images/awareness_dashbord.png"
import AwarenessEmployees from "../../../images/awareness_employees.png"
import classNames from "classnames"
import { useAnalytics } from "../../../hooks/useTracking"

const AnalysisCard = {
  DASHBOARD: "Dashboard",
  EMPLOYEES: "Employees",
}

const AnalysisImg = {
  [AnalysisCard.DASHBOARD]: AwarenessDashboard,
  [AnalysisCard.EMPLOYEES]: AwarenessEmployees,
}

const AwarenessCardsSection = () => {
  const { t } = useTranslation()
  const [activeAnalyseCard, setActiveAnalyseCard] = useState(
    AnalysisCard.DASHBOARD
  )
  const analytics = useAnalytics()

  return (
    <section className="awareness-cards-section container">
      <h2 className="awareness-cards-section__title wt-margin-bottom-15 wt-margin-top-45 wt-text--line-42">
        {t(
          "Everything you need to work with your staff and clients in one application"
        )}
      </h2>
      <div className="awareness-cards-section__blocks">
        <div className="awareness-cards-section__first wt-flex wt-flex-between">
          <div className="awareness-cards-section__first-left background-light">
            <img src={CsvFile} alt="image" />
            <h3 className="awareness-cards-section__block-title wt-text wt-text-gradient--red-blue wt-text--size-550 wt-margin-bottom-20">
              {t("Test everyone")}
            </h3>
            <p className="awareness-cards-section__first-text wt-text wt-text--size-300 wt-text--dark-gray wt-text--line-24">
              {t(
                "Create a unified employee base for your company. Answer employee questions and notify them of updates"
              )}
            </p>
          </div>
          <div className="awareness-cards-section__first-right background-light">
            <div className="awareness-cards-section__projects">
              <img
                className="awareness-cards-section__project1"
                src={Project1}
                alt="img"
              />
              <img
                className="awareness-cards-section__project2"
                src={Project2}
                alt="img"
              />
            </div>
            <h3 className="awareness-cards-section__block-title wt-text wt-text-gradient--red-blue wt-text--size-550 wt-margin-bottom-20">
              {t("Manage easily")}
            </h3>
            <p className="awareness-cards-section__first-text wt-text wt-text--size-300 wt-text--dark-gray wt-text--line-24">
              {t(
                "No need to switch between applications: all your projects and employees are on display"
              )}
            </p>
          </div>
        </div>
        <div className="awareness-cards-section__second wt-flex wt-flex-between">
          <div className="awareness-cards-section__second-left background-dark">
            <div className="awareness-cards-section__templates">
              <img
                className="awareness-cards-section__template1"
                src={DragDropImg}
                alt="img"
              />
              <img
                className="awareness-cards-section__template2"
                src={FacebookImg}
                alt="img"
              />
            </div>
            <h3 className="awareness-cards-section__block-title wt-text wt-text--white wt-text--size-550 wt-margin-bottom-20">
              {t("Create. Test. Navigate")}
            </h3>
            <p className="awareness-cards-section__second-left-text wt-text wt-text--size-300 wt-text--white wt-text--line-24">
              {t(
                "Use ready-made templates or create them yourself. Test the cyber security knowledge such as recognizing phishing emails  of your employees"
              )}
            </p>
          </div>
          <div className="awareness-cards-section__second-right background-light">
            <h3 className="awareness-cards-section__block-title wt-text wt-text-gradient--red-blue wt-text--size-550 wt-margin-bottom-20">
              {t("Schedule tasks")}
            </h3>
            <p className="awareness-cards-section__second-right-text wt-text wt-text--size-300 wt-text--dark-gray wt-text--line-24">
              {t(
                "Schedule tasks for the day, week, month and control their implementation. Link different tools to test a particular group"
              )}
            </p>
            <img src={AwarenessNotebook} alt="img" />
          </div>
        </div>
        <div className="awareness-cards-section__last background-light">
          <div className="awareness-cards-section__last-head wt-flex wt-flex-between wt-flex-middle">
            <div className="awareness-cards-section__last-desc">
              <h3 className="awareness-cards-section__block-title wt-text wt-text-gradient--red-blue wt-text--size-550 wt-margin-bottom-20">
                {t("Analyze your projects")}
              </h3>
              <p className="awareness-cards-section__last-text wt-text wt-text--size-300 wt-text--dark-gray wt-text--line-24">
                {t(
                  "Determine the level of cyber hygiene of your employees, provide assessments and train."
                )}
              </p>
            </div>
            <div className="awareness-cards-section__last-buttons wt-flex">
              <button
                className={classNames("awareness-cards-section__last-btn", {
                  "awareness-cards-section__last-btn_active":
                    activeAnalyseCard === AnalysisCard.DASHBOARD,
                })}
                onClick={() => {
                  analytics.clickChangeTab(AnalysisCard.DASHBOARD)
                  setActiveAnalyseCard(AnalysisCard.DASHBOARD)
                }}
              >
                {t("Dashboard")}
              </button>
              <button
                className={classNames("awareness-cards-section__last-btn", {
                  "awareness-cards-section__last-btn_active":
                    activeAnalyseCard === AnalysisCard.EMPLOYEES,
                })}
                onClick={() => {
                  analytics.clickChangeTab(AnalysisCard.EMPLOYEES)
                  setActiveAnalyseCard(AnalysisCard.EMPLOYEES)
                }}
              >
                {t("Employees")}
              </button>
            </div>
          </div>
          <img src={AnalysisImg[activeAnalyseCard]} alt="img" />
        </div>
      </div>
    </section>
  )
}

export default AwarenessCardsSection
