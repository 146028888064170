import React from "react"
import "./styles.css"
import { useTranslation } from "../../../utils"
import ApplyForm from "../../../components/apply-form"
import { requestAwarenessDemo } from "../../../api/demo-request"
import Awareness from "../../../images/awareness.png"
import { useAnalytics } from "../../../hooks/useTracking"

const AwarenessSection = () => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  return (
    <section className="awareness-section container">
      <div className="awareness-section__left-content">
        <h1 className="awareness-section__title wt-text wt-text-gradient--red-blue">
          {t("Awareness-the most comfortable way to be safe")}
        </h1>
        <p className="awareness-section__description">
          {t(
            "With our awareness app, you can identify and coach your “vulnerable” employees to make them more cyber security conscious. Sit back and relax, we got your back!"
          )}
        </p>
        <ApplyForm
          onOpen={analytics.clickGetStarted}
          analyticsOnSubmit={analytics.clickSend}
          title="Get started"
          request={requestAwarenessDemo}
        >
          <button className="awareness-section__btn wt-button wt-button--red-blue wt-button--lg">
            {t("Get started")}
          </button>
        </ApplyForm>
      </div>
      <div className="awareness-section__right-content">
        <img className="awareness-section__image" src={Awareness} alt="" />
      </div>
    </section>
  )
}

export default AwarenessSection
