import React from "react"
import "./styles.css"
import { useTranslation } from "../../../utils"
import PersonShield from "../../../images/person_shield.svg"
import NotebookImg from "../../../images/notebook.svg"
import UserImg from "../../../images/user.svg"
import SmileImg from "../../../images/aw-smile.png"
import CheckImg from "../../../images/green-check.svg"

const AwarenessMagicSection = () => {
  const { t } = useTranslation()

  return (
    <section
      id="awareness-magic-section"
      className="awareness-magic-section container"
    >
      <div className="awareness-magic-section__first wt-margin-bottom-35">
        <img src={PersonShield} alt="icon" />
        <h2 className="awareness-magic-section__title wt-text wt-text--size-600 wt-margin-bottom-15 wt-margin-top-15">
          {t("Almost like magic")}
        </h2>
        <p className="awareness-magic-section__desc wt-text wt-text--size-300 wt-text--line-24">
          {t(
            "Ensuring the cyber security awareness at the highest level across all of your company departments is vital for your reputation. Awareness helps to put things in order in the company. Processes become more transparent. One click - and you know which departments are well secured and which ones need some training."
          )}
        </p>
      </div>
      <div className="awareness-magic-section__blocks wt-flex wt-flex-between">
        <div className="awareness-magic-section__left background-light">
          <img src={NotebookImg} alt="image" />
          <h3 className="awareness-magic-section__block-title wt-text wt-text--size-550 wt-margin-bottom-20">
            {t("Self defense")}
          </h3>
          <p className="awareness-magic-section__block-desc wt-text wt-text--size-300 wt-text--dark-gray wt-text--line-24">
            {t(
              "Nowadays, emails are the entrenched part of our work. Yours too. Many automated systems are not meant to deal with fraudulent mails. We teach you cyberhygiene and to be ready to protect yourself."
            )}
          </p>
        </div>
        <div className="awareness-magic-section__right background-dark">
          <div className="awareness-magic-section__right-block-smile">
            <img src={SmileImg} alt="img" />
          </div>
          <div className="awareness-magic-section__cards">
            <div className="awareness-magic-section__card-blue">
              <div className="awareness-magic-section__card-check">
                <img src={CheckImg} alt="img" />
              </div>
              <div className="awareness-magic-section__card-user">
                <img src={UserImg} alt="img" />
              </div>
              <div className="awareness-magic-section__card-info">
                <p className="awareness-magic-section__card-desc">
                  Head of accountants department
                </p>
                <h5 className="awareness-magic-section__card-name">
                  Andrey Kovalskiy
                </h5>
                <span className="awareness-magic-section__card-email">
                  ak@thebestsiteever.com
                </span>
              </div>
            </div>
            <div className="awareness-magic-section__card-red">
              <div className="awareness-magic-section__card-smile">
                <img src={SmileImg} alt="img" />
              </div>
              <div className="awareness-magic-section__card-user">
                <img src={UserImg} alt="img" />
              </div>
              <div className="awareness-magic-section__card-info">
                <p className="awareness-magic-section__card-desc">
                  Head of accountants department
                </p>
                <h5 className="awareness-magic-section__card-name">
                  Adam Johnson
                </h5>
                <span className="awareness-magic-section__card-email">
                  vv@thebestsiteever.com
                </span>
              </div>
            </div>
          </div>
          <h3 className="awareness-magic-section__block-title wt-text wt-text--size-550 wt-text--white wt-margin-bottom-20">
            {t("We think like a hacker")}
          </h3>
          <p className="awareness-magic-section__block-desc awareness-magic-section__block-desc_right wt-text wt-text--size-300 wt-text--white wt-text--line-24">
            {t(
              "We offer fast, efficient, and customized for each department solution to detect blind spots in security awareness of your employees. Our web version train your employees against known hacking methods aimed to receive confidential data. "
            )}
          </p>
        </div>
      </div>
    </section>
  )
}

export default AwarenessMagicSection
