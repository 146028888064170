import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import "./styles.css"
import { useTranslation } from "../../../utils"
import AwarenessTablet from "../../../images/awareness_tablet.png"
import { openHubspotChat } from "../../../utils"
import { useAnalytics } from "../../../hooks/useTracking"

const AwarenessTimeSection = () => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  return (
    <section className="awareness-time-section container">
      <div className="awareness-time-section__first wt-margin-bottom-35">
        <h2 className="awareness-time-section__title wt-text wt-text--size-600 wt-margin-bottom-15">
          {t("It's time to start")}
        </h2>
        <p className="awareness-time-section__desc wt-text wt-text--size-300 wt-text--line-24">
          {t(
            "Security of your company and your data directly depends on your employees knowledge"
          )}
        </p>
      </div>
      <div className="awareness-time-section__block background-light wt-flex">
        <div className="awareness-time-section__offer">
          <h2 className="awareness-time-section__offer-title wt-margin-bottom-25">
            <FormattedMessage
              id="Apply for a demo version and learn how to protect your mail and employees"
              defaultMessage="Apply for a demo version and learn how to protect your mail and employees"
              values={{
                blue: (...chunks) => (
                  <span className="wt-text wt-text--blue">{chunks}</span>
                ),
                span: (...chunks) => <span>{chunks}</span>,
              }}
            />
          </h2>
          <button
            onClick={() => {
              analytics.clickGetStarted()
              openHubspotChat()
            }}
            className="awareness-section__btn wt-button wt-button--red-blue wt-button--lg"
          >
            {t("Get started")}
          </button>
        </div>
        <img src={AwarenessTablet} alt="img" />
      </div>
    </section>
  )
}

export default AwarenessTimeSection
